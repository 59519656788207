<template>
    <div class="content">
            <span v-if="rkindex<3" class="number" >{{rkindex+1}}</span>
            <span v-if="rkindex>=3" class="number1">{{rkindex+1}}</span>
            <span class="image"><img  class="img" :src="Ranking.teamImg"></span>
            <span class="name">{{Ranking.teamName}}</span>
            <span v-if="rkindex<3" class="num">{{Ranking.acceptNum}}</span>
            <span v-if="rkindex>=3" class="num1">{{Ranking.acceptNum}}</span>
        </div>
</template>
<script>
export default {
  props:{   
      Ranking:{
            type:Object,
            required:true
        },
         
    rkindex:{
        type:Number
     ,required:true
    }
    }
}
</script>
<style scoped>

.top{
    width: 100%;
    height: 60px;
    /* border: 1px solid rebeccapurple; */

    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:normal;
    color:rgba(0,0,0,1);
}
.top1{ 
     width: 46%;
      height: 60px;
    line-height: 60px;
    /* border: 1px solid firebrick; */
    display: inline-block;
    padding-left: 35px;
}
.top2{
    width: 33%;
     height: 60px;
    line-height: 60px;
    text-align: right;
    display: inline-block;
    /* border: 1px solid forestgreen; */
    padding-right: 35px;
}
.img{    
    width: 70px;
    height: 70px;
    border-radius:50%;
    border: 0;
    }
    .content{
        width: 100%;
        /* height: 100px; */
        display: inline-block;
        /* border: 1px solid rebeccapurple; */
    }
    .content>span{
        /* height: 100px; */
        /* line-height: 100px; */
        display: inline-block;
        /* border: 1px solid darkorange; */
        margin-bottom: 20px;


    }
    .number{
        width: 10%;
        font-family:Microsoft YaHei;
        font-weight:bold;
        color:rgba(253,77,0,1);
        font-size:18px;
        position: relative;
        top: -15px;
        text-align: center;
    }
    .number1{
        width: 10%;
        font-family:Microsoft YaHei;
        font-weight:bold;
        color:#000033;
        font-size:18px;
        position: relative;
        top: -15px;
        text-align: center;
    }
    .num{        
        width: 20%;
        /* width: 40%; */
        text-align: center;
        margin-left: 30px;
        position: relative;
        top: -15px;

        font-size:14px;
        font-family:Microsoft YaHei;
        font-weight:normal;
        color:rgba(252,70,7,1);
    }
     .num1{        
        width: 20%;
        /* width: 40%; */
        text-align: center;
        margin-left: 30px;
        position: relative;
        top: -15px;

        font-size:14px;
        font-family:Microsoft YaHei;
        font-weight:normal;
        color:#000000
    }
    .image{
        width: 22%;
        /* padding-top: 20px; */
        position: relative;
        top: 10px;
    }
    .name{
        /* width: 15%; */
         width: 30%;
        margin-left: 5px;
        position: relative;
        top: -15px;
        /* border: 1px solid red; */
        font-size:14px;
        font-family:Microsoft YaHei;
        font-weight:300;
        color:rgba(0,0,51,1);
    }
</style>