<template>
  <!-- 团队分类 -->
  <div class="content">
    <!-- 路径 -->
    <div class="route">
      <div class="quan">
        <span @click="fanhui()" style="    cursor: pointer;">推荐专家</span>
        <span>></span>
        <span>{{ teamType }}</span>
      </div>
    </div>
    <!-- 搜索框 -->
    <div class="search">
      <div class="long">
        <input type="text" id="search" class="search-icon" @keyup.enter="name" placeholder="输入团队名称" />
        <span class="search-bar"></span>
      </div>
    </div>
    <div class="classification">
      <!-- 左 -->
      <div class="content_left">
        <div class="top">
          <span class="content_left_1">
            <span class="lei">{{ teamType }}</span>
            <span class="shaixuan">
              共筛选出
              <Span>{{ screen }}</Span>个团队
            </span>
          </span>
          <span class="content_left_2">
            排序：
            <span :class="{ sort: sort == 1 }" @click="selectItem(1)">按采纳数</span>
            <span :class="{ sort: sort == 2 }" @click="selectItem(2)">创建时间</span>
          </span>
        </div>
        <!-- 显示主要内容 -->
        <div class="center">
          <!-- <div class="ph" v-for="(item,index) in Dynamics" :key="index"> -->
          <V-List :Dynamic="Dynamic"></V-List>
          <!-- </div> -->
          <V-List></V-List>
        </div>
      </div>
      <!-- 右 -->
      <div class="content_right">
        <div class="team-expert">
          <!-- 业界专家 -->
          <div class="t-label">
            <span class="team-bg">
              <span class="team-icon"></span>
            </span>
            <span class="Ranking">个人专家</span>
            <span class="change2" @click="rotateFlash()">
              <img
                src="~@/assets/icon/change2.png"
                style="width:20px;  cursor: pointer;"
                id="flash-bin"
              />
            </span>
          </div>
          <V-AnswererList :list="expertList"></V-AnswererList>
        </div>
        <!-- 排行榜 -->
        <!-- <div class="bian">
                <div class="content_right_top">
                    <span class="content_right_top_left">{{teamType}}</span>
                    <span class="content_right_top_right">排行榜</span>
        </div>-->
        <!-- <div class="top">
                    <span class="top1">团队名称</span>
                    <span class="top2">采纳数</span>
        </div>-->
        <!-- <div v-for="(item,index) in rankings" :key="index"> 
                <V-Ranking :ranking="item.ranking" :rkindex="index"></V-Ranking>
        </div>-->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { QUERY, UPDATE, DELETE, QUERYED } from "@/services/dao.js";
import List from "@/components/all_questions/classification-list/index.vue";
import AnswererList from "@/views/wisdom-answer/common/answerer-list/index.vue";
import Ranking from "@/views/wisdom-answer/index/team-classification/classification-ranking/index.vue";
export default {
  data() {
    return {
      sort: 1,
      aa: "",
      teamType: "",
      Dynamic: [],
      rankings: [],
      input: " ",
      screen: 0,
      expertList: [],
      n: 0
    };
  },
  components: {
    "V-List": List,
    // "V-Ranking":Ranking,
    "V-AnswererList": AnswererList
  },
  created() {
    this.teamType = this.$route.query.teamType;

    //加载分类信息
    this.classification();
    //查询
    this.screens();
    //团队排行
    //    this.rankinged();
    //业界专家
    this.initExport();
  },
  methods: {
    //c查询
    flite(input) {
      this.name();
    },
    selectItem(index) {
      let data = null;
      this.sort = index;
      if (this.sort == 1) {
        // this.$router.push("/Reward");
        this.classification();
        return;
      }
      if (this.sort == 2) {
        // this.$router.push("/Reward");
        this.time();
      }
    },
    ////按照团队名称查找
    async name() {
      this.input = document.getElementById("search").value;
      if (this.input != "") {
        this.searchs();
      } else {
        this.classification();
      }
    },
    async fanhui() {
      this.$router.push({
        path: "/recommended"
      });
    },
    //按照团队名称查询
    async searchs() {
      this.Dynamic = [];
      this.input = document.getElementById("search").value;
      let data = await QUERY(
        "post",
        "",
        'WDTeamByAccept(where: {teamType: {_eq: "' +
          this.teamType +
          '"}, teamName: {_like: "%' +
          this.input +
          '%"}}) {  id teamType     teamName   acceptNum   teamImg}  '
      );
      //    this.Dynamic.splice(0, this.Dynamic.length);
      for (let i = 0; i < data.data.WDTeamByAccept.length; i++) {
        ///查找显示团队有多少成员
        let info = await QUERY(
          "POST",
          "",
          "  WDTeamMember_aggregate(where: {team: {_eq: " +
            data.data.WDTeamByAccept[i].id +
            "}}) { aggregate {   count   }   }"
        );
        this.Dynamic.push({
          id: data.data.WDTeamByAccept[i].id,
          teamType: data.data.WDTeamByAccept[i].teamType,
          createMan: info.data.WDTeamMember_aggregate.aggregate.count, ///团员数
          teamName: data.data.WDTeamByAccept[i].teamName,
          acceptNum: data.data.WDTeamByAccept[i].acceptNum, //采纳数
          teamImg: this.api.LoginURL.concat(data.data.WDTeamByAccept[i].teamImg)
        });
      }
    },
    ////按照时间排序
    async time() {
      this.Dynamic = [];
      let data = await QUERY(
        "post",
        "",
        'WDTeamByCreateTime(where: {teamType: {_eq: "' +
          this.teamType +
          '"}}) {  id teamType     teamName   acceptNum   teamImg}  '
      );
      // this.Dynamic.splice(0, this.Dynamic.length);
      for (let i = 0; i < data.data.WDTeamByCreateTime.length; i++) {
        ///查找显示团队有多少成员
        let info = await QUERY(
          "POST",
          "",
          "  WDTeamMember_aggregate(where: {team: {_eq: " +
            data.data.WDTeamByCreateTime[i].id +
            "}}) { aggregate {   count   }   }"
        );
        this.Dynamic.push({
          id: data.data.WDTeamByCreateTime[i].id,
          teamType: data.data.WDTeamByCreateTime[i].teamType,
          createMan: info.data.WDTeamMember_aggregate.aggregate.count,
          teamName: data.data.WDTeamByCreateTime[i].teamName,
          acceptNum: data.data.WDTeamByCreateTime[i].acceptNum,
          teamImg: this.api.LoginURL.concat(
            data.data.WDTeamByCreateTime[i].teamImg
          )
        });
      }
    },
    ///团队排行
    //    async rankinged(){
    //     let data=  await   QUERY("post","",'WdTeam(order_by: {acceptNum: desc}, limit: 5, where: {teamType: {_eq: "'+this.teamType+'"}}){  teamName   acceptNum   teamImg}  ');
    //     this.rankings.splice(0, this.rankings.length);
    //       for (let i = 0; i < data.data.WdTeam.length; i++) {
    //         this.rankings.push({
    //           ranking: {

    //             teamName: data.data.WdTeam[i].teamName,
    //             acceptNum: data.data.WdTeam[i].acceptNum,
    //             teamImg: data.data.WdTeam[i].teamImg,
    //           },
    //         });
    //       }
    //    },
    ///业界专家
    async initExport() {
      let info = await QUERY(
        "post",
        "",
        '  WDSpecialist(where: {industry: {_eq: "' +
          this.teamType +
          '"}},limit:' +
          15 +
          "  ,offset:" +
          15 * this.n +
          ' )  {   celebrity  id     img   name industry  wdAnswerNum     wdAcceptNum        }WDSpecialist_aggregate(where: {industry: {_eq: "' +
          this.teamType +
          '"}}){    aggregate {      count    }  }'
      );
      console.log(info.data.WDSpecialist);
      // 依次抵消this.n*4条数据，直到最后一条重置this.n=0
      if (
        (this.n + 1) * 15 <
        info.data.WDSpecialist_aggregate.aggregate.count
      ) {
        this.n++;
      } else {
        this.n = 0;
      }
      this.expertList.splice(0, this.expertList.length);
      for (let i = 0; i < info.data.WDSpecialist.length; i++) {
        //  // 回答个数
        //   let data = await QUERY(
        //     "post",
        //     "",
        //     "   WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
        //     info.data.WDSpecialist[i].id
        //         +
        //       "}}) { aggregate {  count(columns: wdanswerref)    }  }"
        //   );
        this.expertList.push({
          id: info.data.WDSpecialist[i].id * 1,
          img: this.api.LoginURL.concat(info.data.WDSpecialist[i].img),
          name: info.data.WDSpecialist[i].name,
          //  readNum: data.data.WDUserQuestionAnswerRef_aggregate.aggregate.count,//回答个数
          readNum: info.data.WDSpecialist[i].wdAnswerNum,
          acceptNum: info.data.WDSpecialist[i].wdAcceptNum,
          industry: info.data.WDSpecialist[i].industry,
          celebrity:info.data.WDSpecialist[i].celebrity,
        });
      }
    },
    // 最新快讯刷新按钮
    rotateFlash() {
      // js控制刷新图标的动画效果
      let flashIcon = document.getElementById("flash-bin");
      if (!flashIcon.classList.contains("flash-bin")) {
        flashIcon.classList.add("flash-bin");
        setTimeout(() => {
          flashIcon.classList.remove("flash-bin");
        }, 550);
      }
      this.initExport();
    },
    // 筛选多少个团队
    async screens() {
      // this.frame.splice(0,this.frame.length);
      let info = await QUERY(
        "post",
        "",
        '  WDTeamByAccept_aggregate(where: {teamType: {_eq: "' +
          this.teamType +
          '"}}){    aggregate {      count  }  }'
      );
      //alert(info)
      this.screen = info.data.WDTeamByAccept_aggregate.aggregate.count;
      //alert(this.screen)
    },
    ///初始化加载
    async classification() {
      this.Dynamic = [];
      let data = await QUERY(
        "post",
        "",
        'WDTeamByAccept(where: {teamType: {_eq: "' +
          this.teamType +
          '"}}) {  id teamType     teamName   acceptNum   teamImg}  '
      );
      // this.Dynamic.splice(0, this.Dynamic.length);
      for (let i = 0; i < data.data.WDTeamByAccept.length; i++) {
        //alert(data.data.WDTeamByAccept[i].id)
        ///查找显示团队有多少成员
        let info = await QUERY(
          "POST",
          "",
          "  WDTeamMember_aggregate(where: {team: {_eq: " +
            data.data.WDTeamByAccept[i].id +
            "}}) { aggregate {   count   }   }"
        );
        this.Dynamic.push({
          id: data.data.WDTeamByAccept[i].id,
          teamType: data.data.WDTeamByAccept[i].teamType,
          createMan: info.data.WDTeamMember_aggregate.aggregate.count,
          teamName: data.data.WDTeamByAccept[i].teamName,
          acceptNum: data.data.WDTeamByAccept[i].acceptNum,
          teamImg: this.api.LoginURL.concat(data.data.WDTeamByAccept[i].teamImg)
        });
      }
    }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.teamType != from.query.teamType) {
        // this.init();//重新加载数据
      }
    }
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  /* border:1px solid red; */
  display: inline-block;
}
.route {
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 1);
  /* padding-left: 60px; */
  font-size: 15px;
  margin-bottom: 5px;
}
.quan {
  width: 200px;
  position: relative;
  left: 70px;
  top: 20px;
}
.search {
  width: 100%;
  height: 70px;
  background: #0085ff;
}
.content_left {
  width: 69%;
  /* border: 1px solid yellow; */
  display: inline-block;
  margin-right: 10px;
}
.bian {
  /* border:1px solid rgba(210,210,210,1); */
  background: rgba(255, 255, 255, 1);
  margin-top: 40px;
  border-top: 2px solid rgba(54, 153, 255, 1);
}
.content_right {
  width: 28%;
  display: inline-block;
  vertical-align: top;
  margin-top: 70px;
  /* background:rgba(255,255,255,1); */
  /* border:1px solid rgba(210,210,210,1); */
  /* border-top:2px solid  rgba(54,153,255,1);  */
  margin-bottom: 30px;
}
.classification {
  width: 90%;
  /* border:1px solid yellowgreen; */
  /* display: inline-block; */
  margin: 0 auto;
  background-color: #f8f7f6;
}
.content_left_1 {
  width: 70%;
  display: inline-block;
  /* border: 1px solid red; */
  /* height: 40px; */
}
.content_left_2 {
  width: 27%;
  /* height: 40px; */
  display: inline-block;
  /* border: 1px solid lawngreen; */
  margin-left: 20px;
  border-radius: 5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(0, 0, 0, 1);
}
.content_left_2 > span {
  width: 80px;
  height: 35px;
  line-height: 35px;
  border: 1px solid rgba(0, 131, 255, 1);
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.content_left_2 .sort {
  background-color: #3998fb;
  color: white;
  /* cursor:pointer */
}
.top {
  width: 100%;
  line-height: 40px;
  height: 40px;
  margin-bottom: 20px;
  /* border: 1px solid yellow; */
  display: inline-block;
}
.shaixuan {
  font-size: 14px;
  margin-left: 10px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(103, 103, 103, 1);
}
.lei {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.center {
  width: 95%;
  display: inline-block;
  /* border: 1px solid red; */
  background-color: white;
  padding: 20px;
  margin-top: 10px;
}
.content_right_top {
  width: 100%;
  height: 50px;
  /* padding-left: 20px;
    padding-right: 20px; */
  /* border: 1px solid firebrick; */
  margin-top: 30px;
  border-bottom: 1px solid rgba(191, 191, 191, 1);
}
.content_right_top > span {
  height: 50px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: rgba(0, 0, 51, 1);
  line-height: 50px;
}
.content_right_top_left {
  width: 50%;
  height: 50px;
  text-align: left;
  display: inline-block;
  /* border: 1px solid red; */
  margin-left: 20px;
}
.content_right_top_right {
  width: 38%;
  height: 50px;
  display: inline-block;
  text-align: right;
  margin-right: 20px;
  /* border: 1px solid goldenrod; */
}
.search {
  /* margin-top: 50px; */
  text-align: right;
  padding-right: 30px;
  margin-bottom: 10px;
  width: 100%;
}
.search-icon {
  width: 250px;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  /* margin-right: 300px; */
  padding: 3px;
  border-radius: 4px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.search-bar {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(/img/icon.127a0002.png);
  background-position-x: 410px;
  background-position-y: -40px;
  vertical-align: middle;
  /* border: 1px solid red; */
  position: relative;
  left: -35px;
}
.long {
  width: 50%;
  /* border: 1px solid red; */
  position: relative;
  left: 240px;
  top: 20px;
  /* margin: 0 auto; */
}
.team-bg .team-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(~@/assets/icon/team.png);
  margin-top: 5px;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
  background-size: 23px 23px;
}
input {
  outline: none;
}
.Ranking {
  width: 80px;
  height: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 36px;
  margin-left: 5px;
  position: relative;
  top: -3px;
  /* font-weight: bold; */
}
.ph {
  width: 33%;
  display: inline-block;
}
.t-label {
  padding-left: 20px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(210, 210, 210, 1);
  padding-bottom: 10px;
}
.team-expert {
  background-color: white;
}
.top1 {
  width: 46%;
  height: 60px;
  line-height: 60px;
  /* border: 1px solid firebrick; */
  display: inline-block;
  padding-left: 35px;
}
.top2 {
  width: 33%;
  height: 60px;
  line-height: 60px;
  text-align: right;
  display: inline-block;
  /* border: 1px solid forestgreen; */
  padding-right: 35px;
}
.change2 {
  /* display: inline-block;
  text-align: right; */
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}
</style>
