<template>
    <div class="t-items">
        <div class="item" v-for="(item,index) in list" :key="item.id">
            <V-OneAnswerer :index="(index+1)" :name="item.name" :id="item.id" :readNum="item.readNum" :img="item.img" :acceptNum="item.acceptNum" :industry="item.industry"></V-OneAnswerer>
        </div>
    </div>
</template>

<script>
import OneAnswerer from "@/views/wisdom-answer/common/one-answerer/index.vue";
export default {
    props:{
        list:{
            type:Array,
            required:true
        }
    },
    components:{
        'V-OneAnswerer':OneAnswerer
  }
}
</script>

<style>
    .t-items{
        padding: 20px;
    }
    .t-items .item{
        margin-top: 10px;
    }
</style>