<template>
  <div class="one-answerer">
    <div class="as-sn">{{index}}</div>
    <div class="as-img" :class="{'as-bg-1':(1==index),'as-bg-2':(2==index),'as-bg-3':(3==index)}">
      <img v-if="img!==''||img!=null" :src="img" alt @click="see(id)" />
    </div>
    <div class="as-info">
      <div class="as-name" @click="see(id)">{{name}}</div>
      <div class="as-other">
        <span class="read-num">{{industry}}</span>
        <!-- <span class="read-num">{{readNum}}回答</span>
            <span class="point"></span>
        <span class="accept-num">{{acceptNum}}采纳</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    industry: { type: String, required: true },
    readNum: {
      type: Number,
      required: true
    },
    acceptNum: {
      type: Number,
      required: true
    }
  },
  methods: {
    //查看团队成员的回答列表
    async see(id) {
      //alert(id)
      this.$router.push({
        path: "/answer-list",
        query: {
          id: id
        }
      });
    }
  }
};
</script>

<style>
.one-answerer > div {
  display: inline-block;
  vertical-align: middle;
}
.one-answerer .as-sn {
  width: 26px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  font-size: 14px;
  color: #333;
}
.one-answerer .as-img {
  position: relative;
  width: 40px;
  height: 52px;
  background-size: 40px 52px;
  /* background-image: url(https://iknowpc.bdimg.com/static/home/widget/influence/img/first.f968019.png); */
  margin-left: 10px;
  margin-right: 15px;
  cursor: pointer;
}
.as-info {
  margin-top: 10px;
  /* background-color: crimson; */
}
.one-answerer .as-bg-1 {
  background-image: url(~@/assets/icon/first.png);
}
.one-answerer .as-bg-2 {
  background-image: url(~@/assets/icon/second.png);
}
.one-answerer .as-bg-3 {
  background-image: url(~@/assets/icon/third.png);
}
.one-answerer .as-img img {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 34px;
  height: 34px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
}
.one-answerer .as-info .as-name {
  width: 122px;
  height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 14px;
  cursor: pointer;
  font-weight: 600;
  /* text-decoration:underline  */
}
.as-name:hover {
  text-decoration: underline;
}
.one-answerer .as-info .as-other .point {
  display: inline-block;
  width: 2px;
  height: 2px;
  margin-right: 5px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  vertical-align: middle;
  background: #9d9d9d;
  margin-left: 5px;
}
.read-num {
  margin-right: 3px;
  font-size: 14px;
  color: #666;
  vertical-align: middle;
  line-height: 30px;
  /* background-color: burlywood; */
  display: inline-block;
}
.one-answerer .as-info .as-other .accept-num {
  font-size: 12px;
  color: #666;
  vertical-align: middle;
  line-height: 12px;
}
</style>