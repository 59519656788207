<template>
  <!-- 分类列表 -->
  <div class="list">
    <div class="center" v-for="(item,index) in Dynamic" :key="index">
      <span class="left" v-if="item.teamImg!=null|| item.teamImg!=''">
        <img class="img" :src="item.teamImg" />
      </span>
      <span class="right">
        <span>{{item.teamName}}</span>
        <span>团员：{{item.createMan}}</span>
        <span>采纳：{{item.acceptNum}}</span>
        <span>
          <span class="see" @click="see(item.id)">查看详情</span>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    Dynamic: {
      type: Array,
      // required: true
    }
  },
  methods: {
    async see(id) {
      this.$router.push({
        path: "/team-details",
        query: {
          id: id
        }
      });
    }
  }
};
</script>
<style scoped>
.see {
  width: 70px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 131, 255, 1);
  border: 1px solid rgba(0, 132, 255, 1);
  border-radius: 3px;
  cursor: pointer;
}
.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0;
}
.list {
  width: 100%;
  /* height: 200px; */
  display: inline-block;
  /* border: 1px solid yellow; */
  /* padding-top: 20px; */
  background-color: white;
  margin-top: 10px;
}
.center {
  width: 30%;
  height: 160px;
  /* border:1px solid fuchsia; */
  display: inline-block;
  margin-right: 5px;
  /*margin-left: 20px; */
  padding: 10px;
  margin-top: 20px;
}
.left {
  /* border: 1px solid yellow; */
  width: 40%;
  height: 150px;
  display: inline-block;
}
.right {
  width: 50%;
  height: 150px;
  /* border: 1px solid forestgreen; */
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}
.right > span {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}
</style>